import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"
import Banner, {BannerEnum, BannerReference} from "@/models/Banner"

export default class BannerService {


    static typeToName(type: BannerEnum) {
        switch (type) {
            case BannerEnum.SPLASH: return "Bienvenida"
            case BannerEnum.REGULAR: return "Normal"
            case BannerEnum.INTERSTITIAL: return "Pantalla completa"
        }
    }

    static referenceToName(type: BannerReference) {
        switch (type) {
            case BannerReference.HOME_TOP: return "Inicio - Cabecera";
            case BannerReference.PRODUCTS: return "Productos";
            case BannerReference.NOVELTIES: return "Novedades";
            case BannerReference.HOME_TOP_2: return "Inicio - Footer";
        }
    }

    static postBanner(component: Vue, banner: Banner, image: File) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("name", banner.name!)
        formData.set("url", banner.url!)
        formData.set("active", `${banner.active}`)
        formData.set("reference", banner.reference!)
        formData.set("image", image)
        component.axios.post(ConstantTool.BASE_URL + "/api/banners",
            formData, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let item = JsonTool.jsonConvert.deserializeObject(response.data, Banner)
                component.$router.push({path: "/banners/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear el banner"))
            .finally(() => (<any>component).loading = false)
    }

    static async findBanners(component: Vue, banners: Banner[], page: number, size: number, active: boolean | null, reference: BannerReference | null) {
        // @ts-ignore
        component.loading = true

        try {
            let response = await component.axios.get(ConstantTool.BASE_URL + "/public/banners", {
                params: { page: page, size: size, active: active, reference: reference }
            })
            banners.splice(0, banners.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Banner).forEach(v => banners.push(v))
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los banners")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getBanner(component: Vue, bannerId: number) {
        // @ts-ignore
        component.loading = true
        try {
            let response = await component.axios.get(ConstantTool.BASE_URL + "/public/banners/" + bannerId, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.banner = JsonTool.jsonConvert.deserializeObject(response.data, Banner)
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener el banner")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async activateBanner(component: Vue, bannerId: number, active: boolean) {
        // @ts-ignore
        component.loading = true
        try {
            let response = await component.axios.patch(ConstantTool.BASE_URL + "/api/banners/" + bannerId + "/status", null, {
                params: { active: active },
                headers: { Authorization: getModule(SessionModule).session.token }
            })

            // @ts-ignore
            component.banner = JsonTool.jsonConvert.deserializeObject(response.data, Banner)

        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo actualizar el estado el banner")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static patchBanner(component: Vue, bannerId: number, banner: Banner, image: File | null) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("name", banner.name!)
        formData.set("url", banner.url!)
        formData.set("active", `${banner.active!}`)
        formData.set("reference", banner.reference!)
        if (image) formData.set("image", image)

        component.axios.patch(ConstantTool.BASE_URL + "/api/banners/" + bannerId,
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar el banner"))
            .finally(() => (<any>component).loading = false)
    }

    static async clearBanner(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true

        try {
            let response = await component.axios.patch(ConstantTool.BASE_URL + "/api/banners/" + id + "/clear", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            // @ts-ignore
            component.refresh()
            component.$forceUpdate()
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se pudo limpiar el banner")
        } finally {
            // @ts-ignore
            component.loading = false
            component.$forceUpdate()
        }
    }

    static async deleteBanner(component: Vue, id: number) {

        // @ts-ignore
        component.loading = true

        try {
            let response = await component.axios.delete(ConstantTool.BASE_URL + "/api/banners/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo eliminar el banner")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

}
